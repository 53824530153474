import React, { useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"

const ElectronicSignatureFreePremium = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page

    const [faqSelected, setFaqSelected] = useState(page.faq_items[0])

    const itemValueIsEmpty = (item, plan) => {
        return (item.type === "Text" && !item[`${plan}_text`]) || (item.type === "Check" && !item[`${plan}_check`])
    }

    const getItemValueByPlan = (item, plan) => {
        if (itemValueIsEmpty(item, plan)) {
            return <i className="icon-dash" />
        }

        return item.type === "Check" ? <i className="icon-check" /> : item[`${plan}_text`]
    }

    const getItemValueByPlanForMobile = (item, plan) => {
        if (item.type === "Check") {
            return item.name
        }

        return item.name + ': ' + item[`${plan}_text`];
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section purple-dark">
                    <div className="container">
                        <div className="container__50">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            </div>

                            <div className="button__wrapper">
                                <LinkButton button={page.try_premium} className="button button--ghost button--xl" />
                                <LinkButton button={page.know_our_plans} className="button button--secondary button--secondary--ghost button--xl" />
                            </div>
                        </div>

                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.image.alternativeText} image={getImage(page.image.localFile)} />
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="grid-sm-4">
                            {page.metrics.map((metric, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl"> {metric.number}{metric.symbol && metric.symbol}</div>
                                    <span><ReactMarkdown children={metric.description} rehypePlugins={[rehypeRaw]} /></span>
                                </div>
                            })}

                        </div>
                    </div>
                </div>

                {page.sections.map((section, index) => {
                    return <div className={"main__section main__section--50 main__section--50--md" + (index % 2 === 0 ? " main__section--50-invert" : "")} key={index}>
                        <div className="container">
                            <div className="container__50">
                                <h2>{section.title}</h2>
                                <div className="container__text">
                                    <ReactMarkdown children={section.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>
                            <div className="container__50">
                                <GatsbyImage alt={section.image.alternativeText} image={getImage(section.image.localFile)} />
                            </div>
                        </div>
                    </div>
                })}

                <div className="main__section main__section--100 color-section medium-purple">
                    <div className="container">
                        <div className="table--wrapper-white hidden-md" data-aos="fade-up">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table__cell">
                                                <h6>
                                                    {page.features_text}
                                                </h6>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table__cell">
                                                <h4>{page.free_text}</h4>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table__cell">
                                                <h4>{page.premium_text}</h4>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table__cell">
                                                <h4>{page.business_text}</h4>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {page.characteristic_items.map((item, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table__cell">
                                                    <b>{item.name}</b>
                                                    {item.tooltip && <span className="tooltip-icon" data-tooltip={item.tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "free")}
                                                    {item.free_tooltip && <span className="tooltip-icon" data-tooltip={item.free_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "starter")}
                                                    {item.starter_tooltip && <span className="tooltip-icon" data-tooltip={item.starter_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table__cell">
                                                    {getItemValueByPlan(item, "business")}
                                                    {item.business_tooltip && <span className="tooltip-icon" data-tooltip={item.business_tooltip}><i className="icon-info"></i></span>}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="show-md">
                            <div className="tile tile--white--nb">
                                <div className="tile__header">
                                    <h4 className="text--cherry-pie-03">{page.free_text}</h4>
                                </div>
                                <div className="tile__body">
                                    <ul>
                                        {page.characteristic_items.map((item, i) => {
                                            return !itemValueIsEmpty(item, "free") && <li key={i}>
                                                {getItemValueByPlanForMobile(item, "free")}
                                                {item.free_tooltip && <span className="tooltip-icon" data-tooltip={item.free_tooltip}><i className="icon-info"></i></span>}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="show-md">
                            <div className="tile tile--white--nb">
                                <div className="tile__header">
                                    <h4 className="text--cherry-pie-03">{page.premium_text}</h4>
                                </div>
                                <div className="tile__body">
                                    <ul>
                                        {page.characteristic_items.map((item, i) => {
                                            return !itemValueIsEmpty(item, "starter") && <li key={i}>
                                                {getItemValueByPlanForMobile(item, "starter")}
                                                {item.starter_tooltip && <span className="tooltip-icon" data-tooltip={item.starter_tooltip}><i className="icon-info"></i></span>}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="show-md">
                            <div className="tile tile--white--nb">
                                <div className="tile__header">
                                    <h4 className="text--cherry-pie-03">{page.business_text}</h4>
                                </div>
                                <div className="tile__body">
                                    <ul>
                                        {page.characteristic_items.map((item, i) => {
                                            return !itemValueIsEmpty(item, "business") && <li key={i}>
                                                {getItemValueByPlanForMobile(item, "business")}
                                                {item.business_tooltip && <span className="tooltip-icon" data-tooltip={item.business_tooltip}><i className="icon-info"></i></span>}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <LinkButton button={page.explore_all_plans} className="link--big" extras={<i className="icon-arrow-right"></i>} />
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container container--slim">
                        <h2>{page.faq_title}</h2>
                        <div className="item-group item-group--without-image">
                            <div className="item-group__items">
                                {page.faq_items.map((item, index) => (
                                    <div className={`item item--unboxed ${item.id === faqSelected.id ? "item--active" : ""}`} key={index} onClick={() => setFaqSelected(item)}>
                                        <div className="item__header">
                                            <i className="icon-check-circle"></i>
                                            <h4>{item.title}</h4>
                                        </div>
                                        <div className="item__body">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--green">
                            <div className="tile__body">
                                <ReactMarkdown children={page.cta.description} rehypePlugins={[rehypeRaw]} />
                            </div>

                            <LinkButton button={page.cta.button} className="button" extras={<i className="icon-arrow-right"></i>} />
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default ElectronicSignatureFreePremium

export const electronicSignatureFreePremiumQuery = graphql`
    query ($langKey: String) {
        page: strapi2023SignatureFreeVsPremium (locale: { eq: $langKey }) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            description
            try_premium {
                text
                url
                blank
            }
            know_our_plans {
                text
                url
                blank
            }
            image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 664
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            metrics {
                number
                symbol
                description
            }
            sections {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 664
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            features_text
            free_text
            premium_text
            business_text
            enterprise_text
            characteristic_items {
                type
                name
                tooltip
                free_text
                free_check
                free_tooltip
                starter_text
                starter_check
                starter_tooltip
                business_text
                business_check
                business_tooltip
            }
            explore_all_plans {
                text
                url
                blank
            }
            faq_title
            faq_items {
                id
                title
                description
            }
            cta {
                description
                button {
                    text
                    url
                    blank
                }
            }
        }
    }
`
